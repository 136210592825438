<!--
* @description 演示教学
* @fileName demonstrationTeaching.vue
* @author liulian
* @date 2023/07/24 11:39:29
-->
<template>
  <div class="demonstrationTeaching">
    <div class="d_top flex-row-center align-center">
      <div class="d_t_con flex-row-center align-center" id="left_box">
        <div class="d_t_c_left">
          <div
            v-if="lists.length > 0"
            class="t_c_l_title font-size-24 font-weight-500 font-color-white-ffffff"
          >
            <span>{{ lists[0].classifyTitle }}</span>
            <!-- <span style="margin-left: 6px">{{ albumTitle }}</span> -->
          </div>
          <div class="t_c_l_time font-size-18 font-weight-400">
            <span class="c_l_t_span"
              >{{ nowVideo.teachingVideoCount
              }}{{ $t("demonstrationTeaching.text1") }}</span
            >
            <span class="c_l_t_span"
              >{{ $t("demonstrationTeaching.text2") }}：{{ timeLong }}</span
            >
            <span class="c_l_t_span"
              >{{ $t("demonstrationTeaching.text3") }}：{{
                nowVideo.updateTime
              }}</span
            >
          </div>
          <!-- 视频播放 -->
          <div class="t_c_l_video">
            <div
              v-html="nowVideo.htmlUrl"
              class="c_l_v_box"
              v-if="nowVideo.htmlUrl"
            ></div>
            <video
              id="videoId"
              muted="muted"
              class="c_l_v_box"
              ref="videoPlayer"
              controls
              autoplay
              @loadedmetadata="getVideoDuration"
              v-else
            >
              <source
                v-if="nowVideo.teachingVideoUrl"
                :src="nowVideo.teachingVideoUrl"
                type="video/mp4"
              />
            </video>
            <!-- <iframe v-if="nowVideo.teachingVideoUrl && nowVideo.teachingVideoUrl.indexOf('.mov') === -1" style="display: none" allow="autoplay" :src="nowVideo.teachingVideoUrl"></iframe> -->
          </div>
          <img
            v-if="!isexpend"
            id="isexpend_icon"
            class="t_c_l_icon3 cursor-pointer"
            :class="isexpend ? '' : 't_c_l_icon2'"
            src="@/assets/images/userImg/expend_icon@2x.png"
            alt="expend_icon@2x.png"
            @click="expendHadle"
          />
        </div>
        <div
          class="d_t_c_right"
          v-if="isexpend"
          :style="{ height: rightHeight + 'px' }"
        >
          <img
            id="isexpend_icon"
            v-if="isexpend"
            class="t_c_l_icon cursor-pointer"
            :class="isexpend ? '' : 't_c_l_icon2'"
            src="@/assets/images/userImg/expend_icon@2x.png"
            alt="expend_icon@2x.png"
            @click="expendHadle"
          />

          <p class="t_c_r_title t_c_r_title_type font-size-14 font-weight-400">
            {{ $t("demonstrationTeaching.text4") }}：
          </p>
          <p
            class="t_c_r_text font-size-14 font-weight-400"
            v-if="lists.length > 0"
          >
            {{ lists[0].classifyContent }}
          </p>
          <p class="t_c_r_title font-size-14 font-weight-400">
            {{ $t("demonstrationTeaching.text5") }}
          </p>
          <div class="t_c_r_con scroll-style">
            <div
              class="c_r_c_item cursor-pointer"
              :class="item.isActive ? 'c_r_c_item_active' : ''"
              v-for="(item, index) in rightList"
              :key="index"
              @click="clickHandle(item)"
            >
              <div class="c_i_top flex-row align-center">
                <img class="r_c_i_img" :src="item.albumUrl" alt="封面图" />
                <div class="r_c_i_right">
                  <div class="c_r_i_title clamp-1 font-size-14 font-weight-400">
                    {{ item.albumTitle }}
                  </div>
                  <div class="c_r_i_totalNUm font-size-12 font-weight-400">
                    共{{ item.teachingVoList.length }}讲
                  </div>
                </div>
              </div>
              <template v-if="item.isActive">
                <div class="c_i_bottom font-size-10 font-weight-400">
                  <div class="c_i_childs" v-if="item.isExpend">
                    <div
                      class="c_i_c_item font-size-12 font-weight-400 flex-row-between align-center"
                      v-for="(i, index1) in item.teachingVoList"
                      :key="index1"
                      @click="playHandle(i, item)"
                    >
                      <span class="clamp-1">{{ i.teachingTitle }}</span>
                      <span class="bofang" v-if="i.isPly">播放中</span>
                    </div>
                  </div>
                  <div
                    v-if="item.teachingVoList.length > 0"
                    class="c_i_bottom_box flex-row-center align-center"
                  >
                    <template v-if="item.isExpend">
                      <span
                        class="font-size-10 font-weight-400 cursor-pointer"
                        @click="viewHadnle(item)"
                        >收起</span
                      >
                      <img
                        class="c_i_icon cursor-pointer"
                        src="@/assets/images/userImg/shouqi@2x.png"
                        alt="more_icon@2x.png"
                        @click="viewHadnle(item)"
                      />
                    </template>
                    <template v-else>
                      <span
                        class="font-size-10 font-weight-400 cursor-pointer"
                        @click="viewHadnle(item)"
                        >查看专辑全部</span
                      >
                      <img
                        class="c_i_icon cursor-pointer"
                        src="@/assets/images/userImg/more_icon@2x.png"
                        alt="more_icon@2x.png"
                        @click="viewHadnle(item)"
                      />
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div class="d_list">
      <div class="d_l_item" v-for="(item, index) in lists" :key="index">
        <div
          class="l_i_title font-size-24 font-weight-500 font-color-black-1D1D1F"
        >
          {{ item.classifyTitle }}
        </div>
        <div class="l_i_childs flex-row flex-wrap">
          <div
            class="l_i_c_item cursor-pointer"
            v-for="(i, index1) in item.clientAlbumVoList"
            @click="goDetail(item, i)"
          >
            <div class="l_i_c_b">
              <img class="l_i_img" :src="i.albumUrl" alt="i.albumUrl" />
            </div>
            <div
              class="l_i_c_title clamp-1 font-size-16 font-weight-500 font-color-black-1D1D1F"
            >
              {{ i.albumTitle }}
            </div>
            <div
              class="l_i_c_time font-size-14 font-weight-400 flex-row-between align-center"
            >
              <span>{{ i.teachingVideoTotal }}已看</span>
              <span>{{ i.updateTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  classifyListPageApi,
  otherAlbumTeachingApi,
  teachingCountApi,
  listByIdsApi,
} from "@/api/api";
export default {
  name: "demonstrationTeaching",
  components: {},
  data() {
    return {
      isexpend: true,
      rightList: [],
      lists: [],
      nowVideo: {},
      timeLong: "",
      albumTitle: "",
      rightHeight: 588,
    };
  },
  computed: {},
  created() {
    this.getInfo();
  },
  mounted() {
    this.getHeight();
  },
  methods: {
    /*
     * @Description: 获取左侧高度
     * @MethodAuthor:  liulian
     * @Date: 2023-08-09 16:05:56
     * @param: ''
     */
    getHeight() {
      this.rightHeight = 0;
      this.$nextTick(() => {
        // 在这里执行页面已渲染完成后的代码
        let el = document.getElementById("left_box");
        this.rightHeight = el.clientHeight - 40;
      });
    },
    /*
     * @Description: 视频被点击了播放
     * @MethodAuthor:  liulian
     * @Date: 2023-08-01 10:47:17
     * @param: ''
     */
    handleVideoClick() {
      if (this.nowVideo.teachingId) {
        teachingCountApi(this.nowVideo.teachingId).then((res) => {
          if (res.code === 200) {
          }
        });
      }
    },
    /*
     * @Description: 获取播放视频的时长
     * @MethodAuthor:  liulian
     * @Date: 2023-07-27 14:35:43
     * @param: ''
     */
    getVideoDuration() {
      const videoPlayer = this.$refs.videoPlayer;
      const durationSeconds = Math.floor(videoPlayer.duration);
      const minutes = Math.floor(durationSeconds / 60);
      const seconds = durationSeconds % 60;
      this.timeLong = `${minutes} 分 ${
        seconds < 10 ? "0" + seconds : seconds
      } 秒`;
      console.log("开始播放了昂");
      this.handleVideoClick();
    },
    /*
     * @Description: 点击视频
     * @MethodAuthor:  liulian
     * @Date: 2023-07-25 11:08:25
     * @param: ''
     */
    goDetail(item, i) {
      // this.$router.push(`/videoDetail?type=user&albumTitle=${i.albumTitle}&albumId=${i.albumId}`)
      // localStorage.setItem("detailItem", JSON.stringify(i))
      // this.$uniFun.openUrl(`/dnnweb/videoDetail?type=user&albumTitle=${i.albumTitle}&albumId=${i.albumId}`)

      // 将数据编码为URL编码的字符串
      const encodedData = encodeURIComponent(JSON.stringify(i));
      // 打开新窗口，并将数据作为URL参数传递
      this.$uniFun.openUrl(
        `/dnnweb/videoDetail?type=user&albumTitle=${i.albumTitle}&albumId=${i.albumId}&data=${encodedData}`
      );
    },
    /*
     * @Description: 点击播放视频
     * @MethodAuthor:  liulian
     * @Date: 2023-07-25 09:53:12
     * @param: ''
     */
    async playHandle(item, parentItem) {
      parentItem.teachingVoList.length > 0 &&
        parentItem.teachingVoList.map((i) => {
          this.$set(
            i,
            "isPly",
            i.teachingId === item.teachingId && parentItem.albumId === i.albumId
          );
        });

      // 当前正在播放的视频
      this.nowVideo = item;
      this.nowVideo.albumTitle = parentItem.albumTitle;
      if (this.nowVideo.teachingVideoId) {
        let arr = await this.getVideoUrl(this.nowVideo.teachingVideoId);
        if (arr.length > 0) {
          this.$set(this.nowVideo, "teachingVideoUrl", arr[0].url);
        } else {
          this.$set(this.nowVideo, "teachingVideoUrl", "");
        }
        this.$refs.videoPlayer.load(); // 加载新视频
      } else {
        if (this.nowVideo.htmlUrl) {
          let url = this.nowVideo.htmlUrl.replace(
            "<iframe",
            '<iframe height="100%" width="100%"'
          );
          this.$set(this.nowVideo, "htmlUrl", url);
        }
      }
    },
    /*
     * @Description: 点击查看更多视频
     * @MethodAuthor:  liulian
     * @Date: 2023-07-25 09:25:26
     * @param: ''
     */
    viewHadnle(item) {
      this.rightList.map((i) => {
        if (i.albumId === item.albumId) {
          this.$set(i, "isExpend", !item.isExpend);
          if (i.children && i.children.length > 0) {
            i.children.map((j, index) => {
              this.$set(j, "isPly", index === 0);
            });
          }
        }
      });
    },
    /*
     * @Description: 点击播放视频
     * @MethodAuthor:  liulian
     * @Date: 2023-07-24 14:55:30
     * @param: ''
     */
    clickHandle(item) {
      this.rightList.map((i) => {
        this.$set(i, "isActive", item.albumId === i.albumId);
      });
      this.albumTitle = item.albumTitle;
      this.getHeight();
    },
    /*
     * @Description:获取列表
     * @MethodAuthor:  liulian
     * @Date: 2023-07-24 14:09:08
     * @param: ''
     */
    async getInfo() {
      this.lists = await this.getVideoCategroy();
      // 获取第一个视频分类下的视频
      if (this.lists.length > 0) {
        // let data = (await this.getVideos(this.lists[0].classifyId)) || []
        this.rightList = this.lists[0].clientAlbumVoList; // 第一个分类下的专辑列表
        this.rightList.length > 0 && (this.rightList[0].isActive = true);
        this.rightList.length > 0 &&
          this.rightList[0].teachingVoList.length > 0 &&
          (this.rightList[0].teachingVoList[0].isPly = true);
        this.rightList.length > 0 &&
          (this.albumTitle = this.rightList[0].albumTitle); // 第一个分类下的第一个专辑的标题

        // 当前正在播放的视频
        if (
          this.rightList.length > 0 &&
          this.rightList[0].teachingVoList.length > 0
        ) {
          this.nowVideo = this.rightList[0].teachingVoList[0];
          if (this.nowVideo.teachingVideoId) {
            let arr = await this.getVideoUrl(this.nowVideo.teachingVideoId);
            if (arr) {
              this.$set(this.nowVideo, "teachingVideoUrl", arr[0].url);
            }
            this.$refs.videoPlayer.load(); // 加载新视频
          } else {
            if (this.nowVideo.htmlUrl) {
              let url = this.nowVideo.htmlUrl.replace(
                "<iframe",
                '<iframe height="100%" width="100%"'
              );
              this.$set(this.nowVideo, "htmlUrl", url);
            }
          }
        }
        // this.rightList[0].albumUrl = "http://114.215.210.27:9000/public/2023/07/27/8ed27bcda6e247c5b0c9a43db53aaa9b.mp4"
      }

      // this.rightList[0].albumUrl = "http://114.215.210.27:9000/public/2023/07/27/8ed27bcda6e247c5b0c9a43db53aaa9b.mp4"
      // this.nowVideo = this.rightList[0]
    },
    /*
     * @Description: 获取视频
     * @MethodAuthor:  liulian
     * @Date: 2023-08-01 09:43:56
     * @param: ''
     */
    getVideoUrl(ossId) {
      return new Promise((resolve, reject) => {
        listByIdsApi(ossId).then((res) => {
          if (res.code === 200) {
            resolve(res.data);
          }
        });
      });
    },
    /*
     * @Description: 分类下的视频
     * @MethodAuthor:  liulian
     * @Date: 2023-07-27 15:11:50
     * @param: ''
     */
    getVideos(classifyId) {
      return new Promise((resolve, reject) => {
        otherAlbumTeachingApi(classifyId).then((res) => {
          if (res.code === 200) {
            resolve(res.rows);
          }
        });
      });
    },
    /*
     * @Description: 获取所有的分类列表
     * @MethodAuthor:  liulian
     * @Date: 2023-07-27 15:11:37
     * @param: ''
     */
    getVideoCategroy() {
      return new Promise((resolve, reject) => {
        classifyListPageApi().then((res) => {
          if (res.code === 200) {
            resolve(res.rows);
          }
        });
      });
    },
    /*
     * @Description: 展开和收起右侧内容
     * @MethodAuthor:  liulian
     * @Date: 2023-07-24 14:08:54
     * @param: ''
     */
    expendHadle() {
      this.isexpend = !this.isexpend;
    },
  },

  watch: {
    "$i18n.locale": function () {
      this.getInfo();
    },
  },
};
</script>

<style scoped lang="scss">
.demonstrationTeaching {
  min-height: 50vh;
  .d_top {
    height: 760px;
    background: #1d1d1f;
    border: 1px solid yellowgreen;
    .d_t_con {
      width: 1200px;
      min-height: 640px;
      background: #111417;
      color: rgba(255, 255, 255, 0.8);
      padding-left: 20px;
      padding-right: 20px;
      position: relative;
      .d_t_c_left {
        flex: 1;
        height: 100%;
        position: relative;
        .t_c_l_title {
          // margin-top: 24px;
          // height: 24px;
          // line-height: 26px;
          padding-right: 45px;
          padding-top: 28px;
        }
        .t_c_l_time {
          margin-top: 17px;
          height: 24px;
          line-height: 24px;
          .c_l_t_span {
            margin-right: 24px;
          }
          .c_l_t_span:last-child {
            margin-right: unset;
          }
        }
        .t_c_l_video {
          height: 506px;
          width: 100%;
          background: #d8d8d8;
          margin-top: 16px;
          .c_l_v_box {
            width: 100%;
            height: 100%;
          }
        }
      }
      .t_c_l_icon {
        position: absolute;
        top: 0;
        left: -48px;
        width: 48px;
        height: 48px;
      }
      .t_c_l_icon3 {
        position: absolute;
        top: 28px;
        right: 0;
        width: 48px;
        height: 48px;
      }
      .t_c_l_icon2 {
        transform: rotateY(180deg);
      }
      .d_t_c_right {
        width: 260px;
        height: 100%;
        background: #2f3235;
        margin-top: 25px;
        padding-left: 6px;
        padding-right: 5px;
        display: flex;
        flex-direction: column;
        position: relative;

        .t_c_r_title_type {
          padding-top: 25px;
        }
        .t_c_r_title {
          margin-top: 16px;
          height: 20px;
        }
        .t_c_r_text {
          margin-top: 16px;
          text-align: justify;
        }
        p {
          margin-left: 6px;
          margin-right: 7px;
        }
        .t_c_r_con {
          flex: 1;
          margin-top: 14px;
          display: flex;
          flex-direction: column;
          padding-bottom: 17px;
          overflow: hidden;
          overflow-y: auto;

          .c_r_c_item {
            // flex: 1;
            padding-left: 6px;
            // border: 1px solid yellowgreen;
            // background: rgba(29,29,31,0.4);
            background: #2f3235;

            .r_c_i_img {
              width: 120px;
              height: 68px;
              border-radius: 4px;
              margin-right: 6px;
              margin-top: 14px;
              object-fit: cover;
            }
            .r_c_i_right {
              flex: 1;
            }
            .c_r_i_title {
              height: 20px;
            }
            .c_r_i_totalNUm {
              margin-top: 18px;
            }
            .c_i_bottom {
              margin-top: 10px;
              padding-bottom: 10px;
              .c_i_icon {
                width: 12px;
                height: 12px;
                object-fit: cover;
                margin-left: 2px;
              }
              .c_i_childs {
                padding-left: 6px;
                padding-right: 12px;
                .c_i_c_item {
                  margin-bottom: 8px;
                  .bofang {
                    white-space: nowrap;
                    margin-left: 10px;
                  }
                }
              }
            }
          }
          .c_r_c_item_active {
            background: rgba(29, 29, 31, 0.4);
            .r_c_i_img {
              margin-top: 6px;
            }
          }
        }
        .scroll-style {
          width: 100%;
          height: 150px;
          overflow: auto;
        }
        .scroll-style::-webkit-scrollbar {
          width: 0px;
          background: white;
          overflow: hidden;
        }
        .scroll-style::-webkit-scrollbar-corner,
        .scroll-style::-webkit-scrollbar-thumb,
        .scroll-style::-webkit-scrollbar-track {
          border-radius: 4px;
        }
        .scroll-style::-webkit-scrollbar-corner,
        .scroll-style::-webkit-scrollbar-track {
          background-color: rgba(180, 160, 124, 0.5);
          box-shadow: inset 0 0 1px rgba(6, 228, 106, 0.5);
        }
        .scroll-style::-webkit-scrollbar-thumb {
          background-color: yellow;
        }
      }
    }
  }
  .d_list {
    width: 1200px;
    margin: 32px auto;
    .d_l_item {
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 64px;
      padding-bottom: 48px;
      .l_i_title {
        margin-bottom: 32px;
      }
    }
    .d_l_item:last-child {
      border-bottom: unset;
    }
    .l_i_childs {
      gap: 27px;
      .l_i_c_item {
        flex: 0 0 23.3%;
        .l_i_c_b {
          width: 100%;
          height: 158px;
          .l_i_img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .l_i_c_title {
          margin-top: 8px;
          height: 24px;
        }
        .l_i_c_time {
          color: rgba(29, 29, 31, 0.6);
          height: 14px;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
